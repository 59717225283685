<script>
import TransactionalTable from '@/views/CryptoPocketPayment/TransactionalTable.vue'

export default {
    name: 'FilterBar',
    components: {TransactionalTable},
    props: {
        status: {
            type: String,
            default: 'payment_received'
        },
        per_page: {
            type: Number,
            default: 100
        }
    },
    data() {
        return {
            status_value: this.status,
            per_page_value: this.per_page,
            search: {
                input: null,
                loading: false,
                modal: false,
                results: []
            }
        }
    },
    methods: {
        emitFilter() {
            this.$emit('filter', {
                status: this.status_value,
                per_page: this.per_page_value
            })
        },
        searchPayments() {
            this.search.loading = true

            this.$http('/api/crypto-pocket/search', {
                method: 'get',
                headers: {
                    Authorization: `${this.$root.auth.type} ${this.$root.auth.token}`
                },
                params: {
                    query: this.search.input
                }
            })
                .then((res) => {
                    this.search.results = res.data
                    this.search.modal = true
                })
                .catch((err) => {
                    if (err.response.status === 422) {
                        const { data } = err.response
                        const errors = Object.values(data.errors)

                        for (const error of errors) {
                            this.$buefy.toast.open({
                                message: error[0],
                                type: 'is-danger',
                                position: 'is-top-right'
                            })
                        }
                    } else {
                        this.$buefy.toast.open({
                            message: 'Ha ocurrido un error inesperado',
                            type: 'is-danger',
                            position: 'is-top-right'
                        })
                    }
                })
                .finally(() => {
                    this.search.loading = false
                })
        }
    }
}
</script>

<template>
    <div class="columns is-mobile is-flex-wrap-wrap">
        <div class="column is-12">
            <form @submit.prevent="searchPayments">
                <b-field
                    label="Buscar"
                    label-position="on-border"
                    grouped
                >
                    <b-input
                        v-model="search.input"
                        type="search"
                        size="is-small"
                        placeholder="ID transacción o Monto..."
                        :expanded="false"
                        class="mr-0"
                    />

                    <div class="control">
                        <b-button
                            type="is-primary"
                            size="is-small"
                            icon-right="search"
                            :loading="search.loading"
                            @click="searchPayments"
                        />
                    </div>
                </b-field>
            </form>
        </div>

        <div class="column is-12">
            <div class="is-flex is-justify-content-end px-4">
                <b-field
                    label="Estado"
                    label-position="on-border"
                    class="mr-4"
                >
                    <b-select
                        v-model="status_value"
                        size="is-small"
                        @input="emitFilter"
                    >
                        <option
                            v-for="option in [null ,'payment_received', 'started']"
                            :key="`status-${option}`"
                            :value="option"
                        >
                            <template v-if="option === null">
                                Todas
                            </template>

                            <template v-else-if="option === 'payment_received'">
                                Recibido
                            </template>

                            <template v-else-if="option === 'started'">
                                Pendiente
                            </template>
                        </option>
                    </b-select>
                </b-field>

                <b-field
                    label="Por página"
                    label-position="on-border"
                >
                    <b-select
                        v-model="per_page_value"
                        size="is-small"
                        @input="emitFilter"
                    >
                        <option
                            v-for="option in [10, 25, 50, 100]"
                            :key="`per-page-${option}`"
                            :value="option"
                        >
                            {{ option }}
                        </option>
                    </b-select>
                </b-field>
            </div>
        </div>

        <b-modal
            v-model="search.modal"
            scroll="clip"
            :width="720"
        >
            <div
                class="box"
            >
                <div
                    style="max-width: 680px; overflow-x: auto;"
                >
                    <h2 class="subtitle has-text-primary-dark">
                        Resultados de la búsqueda
                    </h2>

                    <TransactionalTable
                        :payments="search.results"
                    />
                </div>
            </div>
        </b-modal>
    </div>
</template>
