<template>
    <div class="container mt-5 px-4">

        <div class="columns">
            <div class="column">
                <h1 class="title is-4 has-text-primary-dark">
                     Nueva Transacción
                </h1>
            </div>
        </div>
        <div class="columns mt-4 px-0">
            <div class="column is-10 is-offset-1">
                <b-tabs position="is-centered"
                        type="is-boxed"
                >
                    <b-tab-item label="Interna"
                                icon="warehouse"
                    >
                        <PendingTransactionsAlert
                            :transaction="transaction"
                        />

                        <div class="column is-12 px-0">
                        <!-- Loaded -->
                        <div class="box">
                            <user-modal v-model="transaction.user" :transaction.sync="transaction" :key="'k1.' + key"/>
                            <payment-received-account class="mt-4" :transaction="transaction" :key="'k2.' + key"/>
                            <user-account class="" :transaction="transaction" :key="'k3.' + key"/>

                            <b-field label="Concepto *"
                              label-position="on-border"
                              class="mt-5">
                                <b-tooltip type="is-dark"
                                position="is-top"
                                :triggers="['focus', 'click']"
                                :auto-close="['outside', 'escape']"
                                style="width: 100%">
                                    <template #content>
                                        <p class="mb-0">
                                            Concepto obligatorio número de identificación
                                        </p>
                                    </template>

                                    <b-input type="text"
                                        rounded
                                        placeholder="Ej. DNI 99999999X"
                                        v-model="transaction.concept"
                                        required
                                    >
                                    </b-input>
                                </b-tooltip>

                            </b-field>
                            <div class="column is-6 has-text-right">
                                <b-button
                                    :label="labelButton()"
                                    :disabled="disabled()"
                                    rounded
                                    type="is-success"
                                    icon-right="check"
                                    @click="sendBank"
                                />
                              </div>
                        </div>
                    </div>
                    </b-tab-item>

                    <b-tab-item label="Externa"
                                icon="shuttle-van"
                    >
                        <div class="column is-12 px-0">
                            <StoreView/>
                        </div>
                    </b-tab-item>
                </b-tabs>
            </div>
        </div>

    </div>  
</template>

<script>
import UserModal from './UserModal.vue';
import UserAccount from './UserAccount.vue';
import PaymentReceivedAccount from './PaymentReceivedAccount.vue';
import StoreView from "@/views/ExternalTransaction/StoreView.vue";
import PendingTransactionsAlert from "./PendingTransactionsAlert.vue";

export default {
        components: {
            PendingTransactionsAlert,
            StoreView,
            UserModal,
            UserAccount,
            PaymentReceivedAccount
        },
        name: 'NewTransaction',
        data() {
            return {
                key: 0,
                transaction: {
                    rate: 1,
                    eurToUsdRate: 1,
                    user: {},
                    accounts: [{
                        amount: 0,
                        account: null,
                    }],
                    bankAccount: {},
                    amount: 20,
                    concept: '',
                    type: 'bank',
                    capture: null,
                    cash: {
                        beneficiary: null,
                        city: 'Caracas',
                        dni: null,
                        phone: null,
                    }
                },
                limpio: null,
                showUserModal: false,
                upload: {
                    loading: false,
                    value: 0,
                }
            };
        },
        mounted() {
            this.getRate();
            this.limpio = {...this.transaction};
        },
        methods: {
            disabled() {
                return this.upload.loading
            },
            labelButton() {
                if (!this.upload.loading) {
                    return 'Enviar';
                }
                return this.upload.value + '%';
            },
            limpiar() {
                this.key ++;
              this.limpio.accounts = [{
                amount: 0,
                account: null,
              }];
                this.transaction = {...this.limpio};

                this.getRate();
            },
            sendBank(){
                this.upload.loading = true

                const formData = new FormData()

                if (this.transaction.user !== undefined && this.transaction.user !== null) formData.append('user_id', (this.transaction.user || {}).id || '');
                if (this.transaction.amount !== undefined && this.transaction.amount !== null) formData.append('amount', this.transaction.amount);
                if (this.transaction.concept !== undefined && this.transaction.concept !== null) formData.append('concept', this.transaction.concept);
                if (this.transaction.type !== undefined && this.transaction.type !== null) formData.append('type', this.transaction.type);

                if (this.transaction.type === 'cash') {
                    ['beneficiary', 'city', 'dni', 'phone'].forEach((v) => {
                        if (this.transaction.cash[v] !== undefined || this.transaction.cash[v] !== null) formData.append(v, this.transaction.cash[v] || '');
                    });
                }
                
                if (this.transaction.bankAccount !== undefined && this.transaction.bankAccount !== null) formData.append('account', (this.transaction.bankAccount || {}).id || '')

                if (this.transaction.type === 'bank' && Array.isArray(this.transaction.accounts) && this.transaction.accounts.length > 0) {
                    for (const [index, value] of this.transaction.accounts.entries()) {
                        formData.append(`accounts[${index}][id]`, (value.account || {}).id || '');
                        formData.append(`accounts[${index}][amount]`, value.amount);
                    }
                }

                if (this.transaction.capture !== undefined && this.transaction.capture !== null) formData.append('capture', this.transaction.capture)

                this.$http('api/transaction/store', {
                    method: 'post',
                    headers: {
                        'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`,
                        'Content-Type': 'multipart/form-data'
                    },
                    data: formData,
                    onUploadProgress: event => {
                        if(event.lengthComputable) {
                            let percentage = Math.round( (event.loaded * 100) / event.total)
                            percentage = percentage > 94 ? 94 : percentage

                            this.upload.value = percentage

                        } else {
                            this.upload.value = 94
                        }
                    }
                })
                .then(() => {
                    this.upload.value = 100
                    this.upload.loading = false
                    this.limpiar();
                    this.$buefy.toast.open({
                        type: 'is-success',
                        message: 'Transacción enviada',
                        position: 'is-top-right',
                        duration: 3000
                    })
                })
                .catch(error => {
                    this.upload.loading = false
                    
                    if(error.response.status === 403) {
                        this.$buefy.toast.open({
                            type: 'is-warning',
                            position: 'is-top-right',
                            duration: 3000,
                            message: error.response.data.message
                        })
                    }

                    if(error.response.status === 422) {
                        let errs = Object.values(error.response.data.errors);
                        errs.forEach(item => {
                            this.$buefy.toast.open({
                                type: 'is-danger',
                                message: item[0],
                                position: 'is-top-right',
                                duration: 3000
                            })
                        })
                    }
                })
            },
            getRate(){
                this.$http.get('api/rate', {
                    params: {
                        token: this.$root.auth.token
                    }
                })
                .then(res => {
                    this.transaction.rate = res.data
                })
                this.$http.get('api/rate?type=cash', {
                    params: {
                        token: this.$root.auth.token
                    }
                })
                .then(({data}) => {
                    this.transaction.eurToUsdRate = data
                });
            },
        },
        computed: {
            montoValido() {
                let sum1 = this.transaction.amount;
                if (this.transaction.accounts.filter((v) => v.amount == 0).length) {
                    return false;
                }
                let sum2 = this.transaction.accounts.reduce((r,v) => {
                    return r + v.amount * 1;
                },0);
                return sum1 == sum2;
            },
        },
    }
</script>