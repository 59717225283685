<template>

  <div class="container mt-5 px-4">

    <section>
        <b-tabs v-model="activeTab">

            <b-tab-item label="Datos">

                <div>

                  <b-field label="Nombres:" :type="nombres ? 'v-bind' : 'is-danger'">
                    <b-input v-model="nombres" placeholder="Ingresa tus nombres"></b-input>
                    <p v-if="!nombres && formSubmitted" class="help is-danger">Complete este campo</p>
                  </b-field>

                  <b-field label="Apellidos:" :type="apellidos ? 'v-bind' : 'is-danger'">
                    <b-input v-model="apellidos" placeholder="Ingresa tus apellidos"></b-input>
                    <p v-if="!apellidos && formSubmitted" class="help is-danger">Complete este campo</p>
                  </b-field>

                  <b-field label="Código de Referencia:">
                    <b-input v-model="codRef" placeholder="0055" disabled></b-input>
                  </b-field>

                  <b-field label="Bolívares:" :type="bolivares ? 'v-bind' : 'is-danger'">
                    <b-input v-model="bolivares" placeholder="Ingresa el monto en bolívares"></b-input>
                    <p v-if="!bolivares && formSubmitted" class="help is-danger">Complete este campo</p>
                  </b-field>

                  <b-field label="Monto:" :type="monto ? 'v-bind' : 'is-danger'">
                    <b-input v-model="monto" placeholder="Ingresa el monto en euros"></b-input>
                    <p v-if="!monto && formSubmitted" class="help is-danger">Complete este campo</p>
                  </b-field>

                  <b-button type="is-success" @click="guardarCambios">Guardar Cambios</b-button>

                </div>

            </b-tab-item>

            <b-tab-item label="Cuentas">
                
            </b-tab-item>

            <b-tab-item  label="Credenciales">
                
            </b-tab-item>

        </b-tabs>
    </section>

    
  </div>
</template>

<script>
  export default {

    data() {
      return {
        activeTab: 0,
        nombres: 'ddd',
        apellidos: '',
        codRef: '123456',
        bolivares: '',
        monto: '',
        formSubmitted: false
        
      }
    },
    methods: {

      guardarCambios() {

        this.formSubmitted = true;
        // Aquí obtienes los valores de los inputs

        if(this.nombres !== "" && this.apellidos !== "" && this.bolivares !== "" && this.monto !== ""){

          console.log('Nombres:', this.nombres);
          console.log('Apellidos:', this.apellidos);
          console.log('Código de Referencia:', this.codRef);
          console.log('Bolívares:', this.bolivares);
          console.log('Monto:', this.monto);
        }
        else{
          console.log("vacios");
        }
        
      }
    }

  }
</script>

